<template>
  <div class="bankTransfer">
    <div class="title">{{ title }}</div>
    <p class="subTitle">
      {{ subTitle }}
    </p>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateMethod)">
        <base-input
          :placeholder="$t('IBAN Account')"
          type="text"
          rules="required"
          name="IBAN Account"
          v-model="account.iban"
        />
        <base-input
          :placeholder="$t('Name Account')"
          type="text"
          rules="required"
          name="Name Account"
          v-model="account.name"
        />
        <p class="subTitle">
          {{ note }}
        </p>

        <base-checkbox
          :label="$t('I understand and agree.')"
          name="Terms and Conditions"
          v-model="agreed"
          rules="required"
        ></base-checkbox>
        <button
          type="submit"
          :class="processing ? 'processing' : ''"
          class="btn btn-primary full"
        >
          {{$t('Send my Bank Acount')}}
        </button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { UPDATE_WITHDRAWL_METHOD } from "@/graphql/user/mutations";
import { MESSAGES } from "@/_helpers/notifications";
export default {
  name: "InfluencersBanktransfer",
  props: {
    title: String,
    subTitle: String,
    note: String,
  },
  data() {
    return {
      processing: false,
      account: {
        iban: null,
        name: null,
      },
      agreement: [{ text: this.$i18n.t('I understand and agree.') }],
      agreed: "",
    };
  },
  methods: {
    updateMethod() {
      this.processing = true;
      let data = JSON.stringify(this.account);
      this.$apollo
        .mutate({
          mutation: UPDATE_WITHDRAWL_METHOD,
          variables: {
            type: "bank",
            data: data,
          },
        })
        .then((data) => {
          if (data) {
            if (data.data.updateWithdrawlMethod.state == "success") {
              this.notify("success", MESSAGES.WITHDRAWL_METHOD_UPDATED);
              this.processing = false;
              this.$emit("submitted");
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bankTransfer {
  ::v-deep {
    .custom-checkbox {
      .is-invalid + .custom-control-label {
        &::before {
          border-color: red;
        }
      }
      .custom-control-label::before {
        border-radius: 5px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
